import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconRiver.module.css';

const IconRiver = props => {
  const { className, rootClassName, width, height } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0,-1004.3622)">
        <path d="m 10.331479,1034.2096 6.73878,-22.4258 7.180668,22.4258 z" fill="#000000" />
        <path d="m 26.889791,1038.8191 8.202144,-32.9206 8.202083,32.9206 z" fill="#000000" />
        <path
          d="m 19.288778,1050.8258 c 0,0 2.956869,-3.3604 2.678551,-6.8343 -0.306023,-3.8198 -4.924154,-2.7133 -4.667043,-6.5366 0.309089,-4.5963 11.378596,-7.8435 11.378596,-7.8435 l 2.651323,-0.1105 c 0,0 -6.520801,3.9748 -6.407365,7.1807 0.132601,3.7475 8.532305,2.824 12.593786,5.733 4.061481,2.909 12.151899,8.4112 12.151899,8.4112 z"
          fill="#000000"
        />
        <path
          d="m 35.079129,1008.1351 -1.36232,5.4683 c 0,0 0.411317,-0.4375 0.68116,-0.4375 0.269844,0 0.411317,0.4375 0.68116,0.4375 0.269843,0 0.411316,-0.4375 0.681159,-0.4375 0.269844,0 0.681161,0.4375 0.681161,0.4375 z"
          fill="#ffffff"
        />
        <path
          d="m 17.070218,1013.5337 -1.493896,4.9712 c 0,0 0.475803,-0.4374 0.771404,-0.4374 0.295602,0 0.475803,0.4374 0.771405,0.4374 0.295602,0 0.475802,-0.4374 0.771404,-0.4374 0.295602,0 0.771405,0.4374 0.771405,0.4374 z"
          fill="#ffffff"
        />
      </g>
    </svg>
  );
};

IconRiver.defaultProps = { className: null };

const { string } = PropTypes;

IconRiver.propTypes = {
  className: string,
};

export default IconRiver;
