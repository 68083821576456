import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDrinkingWater.module.css';

const IconDrinkingWater = props => {
  const { className, rootClassName, width, height } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="4"
    >
      <path
        d="M5 4H19M5 4H4.5M5 4L6 12M19 4H19.5M19 4L18 12M6 12L6.78101 18.2481C6.90612 19.2489 7.75692 20 8.76556 20H15.2344C16.2431 20 17.0939 19.2489 17.219 18.2481L18 12M6 12L8.07203 11.3093C8.65754 11.1142 9.30036 11.2002 9.81388 11.5426L10.8906 12.2604C11.5624 12.7083 12.4376 12.7083 13.1094 12.2604L14.1861 11.5426C14.6996 11.2002 15.3425 11.1142 15.928 11.3093L18 12"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconDrinkingWater.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDrinkingWater.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDrinkingWater;
