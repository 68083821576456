import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPets.module.css';

const IconPets = props => {
  const { className, rootClassName, width, height } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} width={width} height={height} viewBox="0 0 256 256">
      <rect fill="none" height="256" width="256" />
      <line
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        x1="128"
        x2="128"
        y1="192"
        y2="216"
      />
      <circle cx="92" cy="140" r="12" />
      <circle cx="164" cy="140" r="12" />
      <polyline
        fill="none"
        points="144 176 128 192 112 176"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M152,48l55.8-13.9a8,8,0,0,1,9.8,6.2L234,127.9c1.5,8.2-9,13-14.2,6.4Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <path
        d="M104,48,48.2,34.1a8,8,0,0,0-9.8,6.2L22,127.9c-1.5,8.2,9,13,14.2,6.4Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        x1="104"
        x2="152"
        y1="48"
        y2="48"
      />
      <path
        d="M208,119.3V184a32,32,0,0,1-32,32H80a32,32,0,0,1-32-32V119.3"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
};

IconPets.defaultProps = { className: null };

const { string } = PropTypes;

IconPets.propTypes = {
  className: string,
};

export default IconPets;
