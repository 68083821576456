import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_ADDITIONAL_GUEST_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { number } from 'prop-types';

const LineItemAdditionalGuestPriceMaybe = props => {
  const { lineItems, code, intl } = props;

  const translationKey = 'OrderBreakdown.OrderBreakdown.additionalGuestPriceNight';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(
    item => item.code === LINE_ITEM_ADDITIONAL_GUEST_FEE && !item.reversal
  );

  const additionalGuests = unitPurchase ? unitPurchase.seats.toString() : null;
  const nights = unitPurchase ? unitPurchase.units.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return additionalGuests && nights && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <strong>
          <FormattedMessage id={'OrderBreakdown.additionalGuestPrice'} />
        </strong>
        <br />
        <FormattedMessage
          id="OrderBreakdown.additionalGuestPriceNight"
          values={{ unitPrice, additionalGuests, nights }}
        />
      </span>
      <span className={css.baseItemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemAdditionalGuestPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemAdditionalGuestPriceMaybe;
