import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShower.module.css';

const IconShower = props => {
  const { className, rootClassName, width, height } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={width}
      height={height}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="228" r="12" />
      <circle cx="88" cy="200" r="12" />
      <circle cx="28" cy="196" r="12" />
      <circle cx="56" cy="168" r="12" />
      <path
        d="M248,40H219.3a7.9,7.9,0,0,0-5.6,2.3L184,72,55.3,93.4A8,8,0,0,0,51,107l98,98a8,8,0,0,0,13.6-4.3L184,72"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
};

IconShower.defaultProps = { className: null };

const { string } = PropTypes;

IconShower.propTypes = {
  className: string,
};

export default IconShower;
