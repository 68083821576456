import React from 'react';
import PropTypes from 'prop-types';

import css from './IconSync.module.css';

const IconSync = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 8.25C16 7.83579 16.3358 7.5 16.75 7.5C17.1642 7.5 17.5 7.83579 17.5 8.25V11.5C17.5 11.9142 17.1642 12.25 16.75 12.25H14C13.5858 12.25 13.25 11.9142 13.25 11.5C13.25 11.0858 13.5858 10.75 14 10.75H15.2705C14.7674 9.43442 13.4924 8.5 12.0001 8.5C10.907 8.5 9.96324 8.96361 9.32682 9.72938C9.06206 10.0479 8.5892 10.0916 8.27064 9.8268C7.95208 9.56205 7.90846 9.08918 8.17321 8.77062C9.09595 7.66036 10.4632 7 12.0001 7C13.636 7 15.0879 7.78531 16 8.99955V8.25ZM8 15V15.75C8 16.1642 7.66421 16.5 7.25 16.5C6.83579 16.5 6.5 16.1642 6.5 15.75V12.75C6.5 12.3358 6.83579 12 7.25 12H10C10.4142 12 10.75 12.3358 10.75 12.75C10.75 13.1642 10.4142 13.5 10 13.5H8.83669C9.39859 14.6829 10.6047 15.5 12.0001 15.5C13.0707 15.5 14.0368 15.0204 14.6786 14.2655C14.9469 13.9499 15.4202 13.9115 15.7358 14.1798C16.0514 14.4481 16.0897 14.9214 15.8214 15.237C14.9041 16.316 13.5252 17 12.0001 17C10.3638 17 8.91194 16.2143 8 15ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12Z" />
    </svg>
  );
};

IconSync.defaultProps = { className: null };

const { string } = PropTypes;

IconSync.propTypes = { className: string };

export default IconSync;
