import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import css from './OrderBreakdown.module.css';
import { object, number, string } from 'prop-types';

const LineItemAddonFeeMaybe = props => {
  const { addOn, addOnName, intl } = props;

  // const translationKey = 'OrderBreakdown.OrderBreakdown.additionalGuestPriceNight';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';

  const unitsPurchased = addOn.quantity.toString();
  const unitPrice = formatMoney(intl, addOn.unitPrice);
  const total = formatMoney(intl, addOn.lineTotal);
  const addOnTitle = addOnName ?? 'AddOn';

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage
          id="OrderBreakdown.addOnsLineItem"
          values={{ addOnName: addOnTitle, unitPrice, unitsPurchased }}
        />
      </span>
      <span className={css.baseItemValueAddOns}>{total}</span>
    </div>
  );
};

LineItemAddonFeeMaybe.propTypes = {
  addOn: object.isRequired,
  addOnName: string,
  intl: intlShape.isRequired,
};

export default LineItemAddonFeeMaybe;
