import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconPicnicTable.module.css';

const IconPicnicTable = props => {
  const { className, rootClassName, width, height } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24,12a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,24,12Zm0-6a2,2,0,1,0,2,2A2.0021,2.0021,0,0,0,24,6Z" />
      <path d="M26,22H21.8472L21.18,18H24V16H8v2h2.82l-.6668,4H6v2H9.82l-.6668,4H11.18l.6668-4h8.3056l.6668,4h2.0276L22.18,24H26ZM12.18,22l.6665-4h6.3062l.6665,4Z" />
    </svg>
  );
};

IconPicnicTable.defaultProps = { className: null };

const { string } = PropTypes;

IconPicnicTable.propTypes = {
  className: string,
};

export default IconPicnicTable;
