import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-melbourne',
    predictionPlace: {
      address: 'Melbourne, Victoria, Australia',
      bounds: new LatLngBounds(
        new LatLng(37.511273722, 145.512528832),
        new LatLng(-38.433859306, 144.593741856)
      ),
    },
  },
  {
    id: 'default-sydney',
    predictionPlace: {
      address: 'Sydney, New South Wales, Australia',
      bounds: new LatLngBounds(
        new LatLng(-33.578140996, 151.343020992),
        new LatLng(-34.118344992, 150.520928608)
      ),
    },
  },
  {
    id: 'default-gold-coast',
    predictionPlace: {
      address: 'Gold Coast, Queensland, Australia',
      bounds: new LatLngBounds(
        new LatLng(-27.708126368, 153.552171232),
        new LatLng(-28.200317147, 153.186551712)
      ),
    },
  },
  {
    id: 'default-adelaide',
    predictionPlace: {
      address: 'Adelaide, South Australia, Australia',
      bounds: new LatLngBounds(
        new LatLng(-34.652564053, 138.780189824),
        new LatLng(-35.348970061, 138.44212992)
      ),
    },
  },
  {
    id: 'default-perth',
    predictionPlace: {
      address: 'Perth, Western Australia, Australia',
      bounds: new LatLngBounds(
        new LatLng(-31.624485514, 116.239023008),
        new LatLng(-32.675715325, 115.617614368)
      ),
    },
  },
];
export default defaultLocations;
